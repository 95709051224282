<script>
import { computed, defineAsyncComponent } from '@vue/runtime-core'
import { useStore } from 'vuex'
// Load layout components dynamically.
const requireContext = require.context('~/pages/system/steps', false, /.*\.vue$/, 'sync')
const componentNames = requireContext.keys().map((file) => file.replace(/(^.\/)|(\.vue$)/g, ''))
const components = {}
componentNames.forEach((component) => { // component represents the component name
  components[component] = defineAsyncComponent({
    loader: () => import('@/pages/system/steps/' + component + '.vue'),
    delay: 0
  })
})
export default {
  components,
  setup () {
    const store = useStore()
    const currentStepType = computed(() => {
      return slugify(store.getters['system/currentStep'].type)
    })
    function slugify (str) {
      if (typeof str !== 'undefined') {
        return str.toLowerCase()
          // Replaces any - or _ characters with a space
          .replace(/[-_]+/g, ' ')
          // Removes any non alphanumeric characters
          .replace(/[^\w\s]/g, '')
          // Uppercases the first character in each group immediately following a space
          // (delimited by spaces)
          .replace(/ (.)/g, function ($1) { return $1.toUpperCase() })
          // Removes spaces
          .replace(/ /g, '')
      }
    }
    return {
      currentStepType
    }
  }
}
</script>
<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <Component v-if="currentStepType" :is="currentStepType" />
      </div>
    </div>
  </div>
</template>
