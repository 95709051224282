<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()
// computed
const unit = computed(() => 'Stück')
const getFromMainConfig = computed(() => store.getters['system/getFromMainConfig'])
// const getBaseStepsOptions = computed(() => store.getters['system/getBaseStepsOptions'])
const getSelection = computed(() => store.getters['system/getSelection'])

// methods
function onChangeOption (e) {
  store.dispatch('system/setMainOption', {
    id: e.target.value,
    attr: e.target.name,
    checked: e.target.checked
  })
}
function onChangeDimension (e) {
  store.dispatch('system/setDimension', {
    config: e.target.id,
    type: 'width',
    value: e.target.value
  })
}
function sub (e) {
  store.dispatch('system/setQuantity', {
    value: e.target.value,
    action: '-',
    id: e.target.id,
    step: e.target.dataset.parent_id
  })
}
function add (e) {
  store.dispatch('system/setQuantity', {
    value: e.target.value,
    action: '+',
    id: e.target.id,
    step: e.target.dataset.parent_id
  })
}
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(step, index) in getFromMainConfig" :key="index">
          {{ step.name }}

      <template v-if="step && step.options">
        <div v-for="(option, index) in step.options" :key="index">
          <!-- select/radio -->
          <div class="form-check" v-if="option.type === 'select'">
            <input type="radio" :id="option.id" class="form-check-input" :value="option.id"  :name="step.id" :data-step="step.id" :checked="option.selected" @change="onChangeOption">
            <label class="form-check-label" :for="option.id">
              {{ option.name }} <Tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></Tooltip>
            </label>
          </div>
          <!-- check -->
          <div class="form-check" v-if="option.type === 'check'">
            <input type="checkbox" :id="option.id" class="form-check-input" :value="option.id"  :name="step.id" :data-step="step.id" :checked="option.selected" @change="onChangeOption">
            <label class="form-check-label" :for="option.id">
              {{ option.name }} <Tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></Tooltip>
            </label>
          </div>
          <!-- count -->
          <template v-if="option.type === 'count'">
            <div class="form-check-label" :for="option.id">
              {{ option.name }} <Tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></Tooltip>
            </div>
            <div class="form-group d-flex">
              <input type="text" :value="option.value + ' ' + unit" class="form-control" :disabled="!option.opened">
              <button class="btn btn-primary mx-1"  @click="sub" :id="option.id" :data-parent_id="step.id" :class="{ 'disabled': !option.opened }">-</button>
              <button class="btn btn-primary" @click="add" :id="option.id" :data-parent_id="step.id" :class="{ 'disabled': !option.opened }">+</button>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
<!--    <div class="row">-->
<!--      <div class="col-md-4" v-for="(parent, index) in getFromMainConfig.parent" :key="index">-->
<!--        <div class="form-check">-->
<!--          <input type="radio" :id="parent.id" class="form-check-input" :value="parent.id" name="parent" :checked="parent.selected" @change="onChangeOption">-->
<!--          <label class="custom-control-label" :for="parent.id">-->
<!--            {{ parent.name }} <Tooltip :tooltip="parent.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></Tooltip>-->
<!--          </label>-->
<!--        </div>-->

<!--        <template v-if="option && option.show_for[0] === parent.id && parent.selected">-->
<!--          <div v-for="(option, index) in getFromMainConfig.child" :key="index">-->
<!--            &lt;!&ndash; select/radio &ndash;&gt;-->
<!--            <div class="form-check" v-if="option.type === 'select'">-->
<!--              <input type="radio" :id="option.id" class="form-check-input" :value="option.id"  :name="parent.id" :data-step="parent.id" :checked="option.selected" @change="onChangeOption">-->
<!--              <label class="form-check-label" :for="option.id">-->
<!--                {{ option.name }} <Tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></Tooltip>-->
<!--              </label>-->
<!--            </div>-->
<!--            &lt;!&ndash; check &ndash;&gt;-->
<!--            <div class="form-check" v-if="option.type === 'check'">-->
<!--              <input type="checkbox" :id="option.id" class="form-check-input" :value="option.id"  :name="parent.id" :data-step="parent.id" :checked="option.selected" @change="onChangeOption">-->
<!--              <label class="form-check-label" :for="option.id">-->
<!--                {{ option.name }} <Tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></Tooltip>-->
<!--              </label>-->
<!--            </div>-->

<!--            <div class="form-group d-flex" v-if="option.type === 'count'">-->
<!--              <input type="text" :value="option.value + ' ' + unit" class="form-control" disabled>-->
<!--              <button class="btn btn-primary mx-1"  @click="sub" :id="option.id" :data-parent_id="parent.id">-</button>-->
<!--              <button class="btn btn-primary" @click="add" :id="option.id" :data-parent_id="parent.id">+</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </template>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row">
      <div class="col-md-4">
        <div>
          <label class="">Breite</label>
          <div class="input-group mb-2">
            <select class="form-select" id="main" @change="onChangeDimension">
              <option v-for="selectOption in getSelection" :value="selectOption.value" :key="selectOption.value" :selected="selectOption.selected">{{ selectOption.label }}</option>
            </select>
              <div class="input-group-text">Zentimeter</div>
          </div>
        </div>
      </div>
    </div>
</template>
